import axios from "axios";
import { getSession, signOut } from "next-auth/react";
export interface ResponseApiClient<T> {
	data?: T | null;
	errors?: string[];
	status_code?: number;
}

const AxiosInstance = axios.create({
	timeout: 1000 * 10,
	headers: {
		"Content-Type": "application/json",
	},
});

AxiosInstance.interceptors.request.use(async (request) => {
	const requestUrl = request.url;
	const arrUser = [
		"customers",
		"users",
		"user",
		"cart",
		"invoice",
		"locations",
		"wishlists",
		"employees",
		"shippingcarriers",
		"preorder",
	];

	if (requestUrl) {
		if (
			!requestUrl.includes("public") &&
			arrUser.some((i) => requestUrl.includes(i))
		) {
			const session = await getSession();
			if (session) {
				request.headers.Authorization = `${session.user.jwt}`;
			}
		}
	}

	//if this is GET method, we append jwt to param, to prevent prefetch request
	if (request.method === "get") {
		request.params = {
			...request.params,
		};
	} else {
		//append company_id to POST/PUT request data
		request.data = {
			...request.data,
		};
	}

	return request;
});

AxiosInstance.interceptors.response.use(
	(response) => {
		const { config, status, data, headers } = response;

		// console.log("AxiosResponse:", {
		// 	URL: config.url,
		// 	HTTP_Status: status,
		// 	ExecutionTime: headers["execution-time"],
		// 	Data: data,
		// });
		return response;
	},
	async (error) => {
		// if (false) {
		// 	if (error.response) {
		// 		console.log("AxiosErrors:", {
		// 			URL: error.response.config.url,
		// 			HTTP_Status: error.response.status,
		// 			Data: error.response.data,
		// 		});
		// 	} else if (error.request) {
		// 		console.log("AxiosErrors:", {
		// 			URL: error.response.config.url,
		// 			HTTP_Status: error.response.status,
		// 			Data: "The request was made but no response was received",
		// 		});
		// 	} else {
		// 		console.log("Error", error.message);
		// 	}
		// }

		if (error.response) {
			if (error.response.status >= 500) {
				throw new Error("Server Error");
			}
			if (error.response.status === 401) {
				// await signOut({ redirect: true, callbackUrl: "/" });
			}
		}

		return Promise.reject(error);
	}
);

export default AxiosInstance;
