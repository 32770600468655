import { AxiosError, isAxiosError } from "axios";
import BaseCollection from "../collections/BaseCollection";
import { BaseCollectionJson } from "../types/BaseCollection.type";
import { BaseModelJson } from "../types/BaseModelJson.type";
import Error, { ErrorDetail, ErrorServer } from "./Error";

type TypeFetch = "server" | "client";
class BaseApi {
	debug: boolean;

	constructor(debug: boolean) {
		this.debug = debug;
	}

	//Handing error of request
	static handleError(axiosError: any) {
		let statusCode = 0;
		let errors = [];
		let error_detail: ErrorDetail[] = [];
		let active_phone: string = "";
		let time: number = 0;
		let url : any = "";
		if(isAxiosError(axiosError)){


		if (axiosError != null) {
			url = axiosError?.response?.config?.url;
			if (
				axiosError.hasOwnProperty("response") &&
				typeof axiosError.response !== "undefined"
			) {
				statusCode = axiosError.response.status;
				if (axiosError.response.data?.["error"] != null) {
					if (typeof axiosError.response.data["error"] == "string") {
						errors.push(axiosError.response.data["error"]);
					} else if (typeof axiosError.response.data["error"] == "object") {
						errors = axiosError.response.data["error"];
						if (
							axiosError.response.status === 406 &&
							axiosError.response.data.hasOwnProperty("error_detail")
						) {
							error_detail = axiosError.response.data["error_detail"];
						}
						if (axiosError.response.data.hasOwnProperty("active_phone")) {
							active_phone = axiosError.response.data["active_phone"];
						}
						if (axiosError.response.data.hasOwnProperty("time")) {
							time = axiosError.response.data["time"];
						}
					}
				} else {
					errors.push(axiosError.response.statusText);
				}
			} else {
				statusCode = 500;
				errors.push("api_not_response");
				error_detail.push({ ["response"]: JSON.stringify(axiosError) });
			}
		} 
	}else if(axiosError.status){
		console.log(axiosError)
		statusCode = axiosError.status
		if(axiosError?.response?.error){
			errors.push(...axiosError.response.error);
		}
	}	else {
			console.log(axiosError)
			errors.push("api_error_unexpected");
		}
		return new Error({
			statusCode,
			errors,
			error_detail,
			active_phone,
			time,
			url: url,
		});
	}

	static defaultError(
		errors: string[],
		statusCode: number,
		error_detail?: ErrorDetail[]
	) {
		const error_detail_convert = error_detail || [];
		return new Error({
			statusCode,
			errors,
			error_detail: error_detail_convert,
		});
	}
	static handleErrorServer(serverErorr: ErrorServer | null) {
		let statusCode: number = 0;
		let errors: string[] = [];
		let error_detail: ErrorDetail[] = [];
		if (serverErorr != null && serverErorr.error.length > 0) {
			errors = serverErorr.error;
			statusCode = serverErorr.status;
		}

		return new Error({ statusCode, errors, error_detail });
	}

	static handleErrorCore(serverErorr: ErrorServer | null) {
		let statusCode: number = 0;
		let errors: string[] = [];
		let error_detail: ErrorDetail[] = [];
		let url: string = "";

		if (serverErorr != null && serverErorr.error.length > 0) {
			// url = serverErorr.request.url
			errors = serverErorr.error;
			statusCode = serverErorr.status;
			if (serverErorr.error_detail && serverErorr.error_detail.length > 0) {
				error_detail = serverErorr.error_detail;
			}
		}

		return new Error({ statusCode, errors, error_detail, url: url });
	}

	// async getFullDataFetcher<
	// 	MJson,
	// 	M extends BaseModelJson<MJson>,
	// 	J extends BaseCollectionJson<MJson>,
	// 	C extends BaseCollection<MJson, M, J>
	// >({
	// 	collection,
	// 	companyId,
	// 	version,
	// 	url,
	// }: {
	// 	collection: C;
	// 	companyId: number;
	// 	version: number;
	// 	url: string;
	// }): Promise<C> {
	// 	try {
	// 		let queryData = {
	// 			params: {
	// 				company_id: companyId,
	// 				version: version,
	// 			},
	// 		};
	// 		const response = await apiClient().get<any>(url, queryData);
	// 		if (response.hasOwnProperty("data")) {
	// 			collection.fromJson({
	// 				total: response.data.length,
	// 				limit: response.data.length,
	// 				items: response.data,
	// 				currentpage: 1,
	// 			} as J);
	// 		}
	// 	} catch (error) {
	// 		collection.withError(BaseApi.handleError(error));
	// 	}

	// 	return collection;
	// }
}

export default BaseApi;
